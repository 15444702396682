import React from 'react';
import SEO from '../components/seo';
import styled from 'styled-components';
import Layout from '../components/layout';
import ParkMapImage from '../images/park-map.jpg';
import SwimmingPoolImage from '../images/pool.jpeg';
import MiniGolfImage from '../images/mini_golf.jpeg';
import KitchenArea from '../images/kitchen-area.jpeg';
import BathroomsImage from '../images/bathrooms.jpeg';
import LaundryImage from '../images/laundry.jpeg';
import Bonfire from '../images/bonfire.jpg';
import { ImageGrid3 } from '../components/image-grid';
import { subheadingMixin, OutboundButton } from '../theme/mixins';
import { PageBanner } from '../components/page-banner';
import { FeaturesList } from '../components/features-list';

const Subheading = styled.h2`
  ${subheadingMixin}
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const ContentSection = styled.section`
  margin: 50px 100px 62px 100px;

  @media only screen and (max-width: 1000px) {
    margin: 20px 32px 42px 32px;
  }
`;

const MapSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 100px 62px 100px;

  @media only screen and (max-width: 1000px) {
    margin: 20px 32px 42px 32px;
  }

  img {
    width: 100%;
    margin-bottom: 42px;
  }
`;

const FacilitiesMap = () => {
  const facilities = [
    {
      images: [SwimmingPoolImage],
      title: 'Swimming Pool Area',
    },
    {
      images: [KitchenArea],
      title: 'Camp Kitchen',
    },
    {
      images: [BathroomsImage],
      title: 'Toilet Amenities Block',
    },
    {
      images: [LaundryImage],
      title: 'Laundry Services',
    },
    {
      images: [MiniGolfImage],
      title: 'Mini Golf',
    },
    {
      images: [Bonfire],
      title: 'Bonfire Area',
    },
  ];

  return (
    <Layout useContentWrapper={false}>
      <SEO title="Facilities &amp; Map" />
      <PageBanner title="Facilities and Park Map" />
      <ContentSection>
        <Subheading>Shared Facilities</Subheading>
        <ImageGrid3 array={facilities} />
      </ContentSection>
      <MapSection>
        <Subheading>Map of the Park</Subheading>
        <b>Management to allocate sites only</b>
        <br />
        <img alt="park map" src={ParkMapImage} />
        <OutboundButton href={ParkMapImage} download="Peterborough_Caravan_Park_Map">
          Download the Map
        </OutboundButton>
      </MapSection>
      <FeaturesList heading="Plus many more things" />
    </Layout>
  );
};

export default FacilitiesMap;
